import React from "react"
import Img from "gatsby-image"
import cx from "classnames"
import styles from "./banner.module.scss"

/**
 * Full-width call to action banner with small image and title/paragraph text
 */
const CTABanner = ({ primary, items }) => {
  const imageDiv = (
    <div className="columns small-12 medium-3">
      <div className={styles.image}>
        <Img
          className="no-a11y-transform"
          fluid={primary.image.fluid}
          alt={primary.image.alt}
          role="img"
          aria-label={primary.image_label}
        />
      </div>
    </div>
  )
  return (
    <section
      className={styles.banner}
      style={{ backgroundColor: primary.color }}
    >
      <div className="row">
        {!primary.image_side && imageDiv}
        <div className={cx("columns small-12 medium-9", styles.cta)}>
          <h3>{primary.call_to_action.text}</h3>
          <div dangerouslySetInnerHTML={{ __html: primary.description.html }} />
          {items.map(({ button_action, button_label }) => (
            <a
              href={button_action.url}
              className="button"
              key={button_label.text}
            >
              {button_label.text}
            </a>
          ))}
        </div>
        {primary.image_side && imageDiv}
      </div>
    </section>
  )
}

export default CTABanner
