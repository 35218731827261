import React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import FeaturedStory from "../components/FeaturedStory"
import StoryTabs from "../components/StoryTabs"
import VideoStories from "../components/VideoStories"
import PodcastStories from "../components/PodcastStories"
import ArticleStories from "../components/ArticleStories"
import InstagramFeed from "../components/InstagramFeed"
import HomepageHero from "../components/HomepageHero"
import SocialButtons from "../components/SocialButtons"
import CTABanner from "../components/CTABanner"
import Schools from "../components/Schools"

const Homepage = ({ data }) => {
  const sliceComponents = {
    PrismicHomepageBodyFeaturedStory: FeaturedStory,
    PrismicHomepageBodyStoryTabs: StoryTabs,
    PrismicHomepageBodyVideoStories: VideoStories,
    PrismicHomepageBodyPodcastStories: PodcastStories,
    PrismicHomepageBodyArticleStories: ArticleStories,
    PrismicHomepageBodyJuicerFeed: InstagramFeed,
    PrismicHomepageBodyHomepageHero: HomepageHero,
    PrismicHomepageBodySocialButtons: SocialButtons,
    PrismicHomepageBodyFullWidthCtaBanner: CTABanner,
    PrismicHomepageBodySchools: Schools,
  }
  const homeData = data.allPrismicHomepage.edges[0].node.data
  return (
    <Layout>
      <SEO
        title={homeData.title.text}
        description={homeData.meta_description.text}
        image={homeData.meta_image.url}
      />
      {homeData.body.map(({ __typename, ...props }, i) => {
        if (sliceComponents[__typename])
          return React.createElement(sliceComponents[__typename], {
            ...props,
            // eslint-disable-next-line react/no-array-index-key
            key: `${__typename}-${i}`,
          })
        return <p key={__typename}>TODO: {__typename}</p>
      })}
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicHomepage {
      edges {
        node {
          prismicId
          id
          data {
            title {
              text
            }
            meta_image {
              url
            }
            meta_description {
              text
            }
            body{
              __typename
               ... on PrismicHomepageBodyHomepageHero {
                primary {
                  hero_content{
                    html
                  }
                  hero_image{
                    fluid(maxWidth: 900) {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                  hero_video{
                    embed_url
                    html
                  }
                  form_header
                  form_placeholder
                  form_button_label
                }
              }
              ... on PrismicHomepageBodyFeaturedStory {
                primary {
                  section_header
                  story_content{
                    html
                  }
                  embd_media{
                    embed_url
                  }
                }
              }
              ... on PrismicHomepageBodySchools {
                id
                primary {
                  header
                  major_stat_number_1
                  major_stat_description_1
                  major_stat_number_2
                  major_stat_description_2
                  schools_image{
                    fluid(maxWidth: 900) {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                }
                id
                items {
                  minor_stat_number
                  minor_stat_description
                }
              }
              ... on PrismicHomepageBodyStoryTabs {
                primary{
                  section_content{
                    html
                  }
                  videos_tab_header
                  podcasts_tab_header
                  articles_tab_header
                  instagram_tab_header
                }
              }
              ... on PrismicHomepageBodyPodcastStories {
                primary{
                  section_header
                  embed_link{
                    url
                  }
                }
              }
              ... on PrismicHomepageBodyVideoStories {
                primary{
                  section_header
                  view_all_text
                  view_all_link{
                    url
                  }
                  project_shorts_text
                  project_shorts_link{
                    url
                  }
                  project_docs_text
                  project_docs_link{
                    url
                  }
                }
              }
              ... on PrismicHomepageBodyArticleStories {
                primary{
                  section_header
                  button_text
                }
              }
              ... on PrismicHomepageBodyFullWidthCtaBanner {
                id
                primary {
                  color
                  image_side
                  image {
                    fluid(maxWidth: 220) {
                      ...GatsbyPrismicImageFluid
                    }
                    alt
                  }
                  image_label
                  description {
                    html
                  }
                  call_to_action {
                    text
                  }
                }
                items {
                  button_action {
                    url
                  }
                  button_label {
                    text
                  }
                }
              }
              ... on PrismicHomepageBodyJuicerFeed {
                primary {
                  heading {
                    text
                  }
                  view_all_text
                  view_all_link{
                    url
                  }
                }
              }
              ... on PrismicHomepageBodySocialButtons {
                id
                primary {
                  above {
                    html
                  }
                  below {
                    html
                  }
                }
                items {
                  logo
                  logo_label
                  color
                  link {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPreview(Homepage)