import React, { useRef, useState } from "react"
import Img from "gatsby-image"
import cx from "classnames"
import styles from "./homepagehero.module.scss"
import useWindowResize from "../../hooks/use-window-resize"
import addToMailchimp from 'gatsby-plugin-mailchimp'
/**
 * Renders title text and description.
 */
const HomepageHero = ({ primary }) => {
  const {
    hero_content,
    hero_image,
    hero_video,
    form_header,
    form_placeholder,
    form_button_label
  } = primary

  const logotype = useRef()
  const section = useRef()

  useWindowResize(() => {
    if (section.current && logotype.current) {
      const hContainer = section.current.clientHeight
      const hLogo = logotype.current.clientHeight
      const offset = (hContainer - hLogo) / 2
      const top = `${offset}px`
      logotype.current.style.top = top
    }
  })

  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    // Mailchimp always responds with status code 200, accompanied by a string indicating the result of the response.
    const { result, msg } = await addToMailchimp(email);
    result === 'success' && setEmail('');
    // Removes the HTML returned in some response messages in case of error
    setMessage(msg.split('<')[0]);
    setStatus(result);
  };

  const handleChange = event => setEmail(event.target.value);

  return (
    <section
      className={cx(
        styles.homepageHero,
      )}
      ref={section}
    >
      <div className="grid-container full" style={{ maxWidth: "1280px"}}>
        <div className="grid-x grid-padding-x" style={{alignItems:"center", justifyContent:"space-between"}}>
          <div className="cell large-6" >
            <div className={cx("grid-x",styles.copy)}>
              <div className="cell large-11 medium-8"
                dangerouslySetInnerHTML={{ __html: hero_content?.html }}
              />
              <div className="cell medium-8 ">
                <form className={styles.heroForm}>
                    <label htmlFor="fname">{form_header}</label>
                    <input type="email" id="fname" placeholder={form_placeholder} required onChange={handleChange}/>
                  <input type="submit" value={form_button_label} onClick={handleSubmit}/>
                  <p status={status}>
                    {message}
                  </p>
                </form>
              </div>
            </div>
          </div>
          <div className="cell large-6">
            <div dangerouslySetInnerHTML={{ __html: hero_video?.html }} /> 
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomepageHero
