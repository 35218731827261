import React from "react"
import styles from "./schools.module.scss"
import Img from "gatsby-image"
/**
 * Renders title text and description.
 */
const Schools = ({ id,primary,items }) => {
  return (
    <section className={styles.Schools}>
      <div className="grid-container full" style={{ maxWidth: "1280px"}}>
        <div>
          <div className={styles.Schools___cards}>
            <h2>{primary.header}</h2>
            <div className={styles.flexWrap}>
              {items.map((item,index) => (
                <div className={styles.card___minor} key={id+index}>
                  <h4>{item.minor_stat_number}</h4>
                  <p>{item.minor_stat_description}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.Schools___image}>
            <div className={styles.Schools___majorCards}>
              <div className={styles.card___major}>
                <h4>{primary.major_stat_number_1}</h4>
                <p>{primary.major_stat_description_1}</p>
              </div>
              <div className={styles.card___major}>
                <h4>{primary.major_stat_number_2}</h4>
                <p>{primary.major_stat_description_2}</p>
              </div>
            </div>
            <Img fluid={primary.schools_image.fluid} 
              alt={primary.schools_image?.alt || ""} className={styles.card___imageWrap}  role="img"/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Schools
